<template>
	<div class="cstom">
		<slot name="title">NCS 자가평가(직무 / 등급)</slot>
		<template v-for="idx in size">
			<div :key="index + idx" class="ncs_list before" v-if="item(idx) == null"><p >- 평가전 -</p></div>
			<div :key="index + idx" class="ncs_list before item" v-else-if="item(idx).evlYn != 'Y'" @click="$emit('clickItem', item(idx))"><p>{{item(idx).ncsResult}} &#9654;</p></div>
			<div :key="index + idx" class="ncs_list" v-else @click="$emit('clickItem', item(idx))"><p>{{item(idx).ncsResult}} &#9654;</p></div>
		</template>
		<div class="updown_btn" v-if="this.info.ncsGrade && this.info.ncsGrade.length > size">
			<img src="/images/up_btn_ncs.png" alt="업버튼" @click="updown(-1)">&nbsp;
			<img src="/images/down_btn_ncs.png" alt="다운버튼" @click="updown(1)">
		</div>
	</div>
</template>

<script>
export default {
	props:{
		value:Object, size: { type:Number, default:3 }
	},
	watch:{
		value(){
			this.init();
		}
	},
	data(){
		return { 
			tecSeq: 0,
			index : 0
		}
	},
	beforeMount(){
		this.init();
	},
	methods: {
		init(){
			if(this.tecSeq != this.info.tecSeq){
				this.tecSeq = this.info.tecSeq;
				this.index = 0;
			}
		},
		item(idx){
			if(!this.info.ncsGrade || this.info.ncsGrade.length < this.index + idx) return null;
			else return this.info.ncsGrade[this.index + idx - 1];
		},
		updown(dif){
			if(this.info.ncsGrade){
				var val = this.index + dif;
				if(val >= 0 && val <= this.info.ncsGrade.length - this.size){
					this.index = val;
				}
			}
		}
	},
	computed:{
		info(){
			return this.value && this.value.tecSeq ? this.value : {};
		}
	}
}
</script>