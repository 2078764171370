<template>
	<div>
		<span class="name">{{info.mberNm}}</span>
		<span class="age">({{info.gender | gender}}/{{ info.birthYyyymmdd | birthYear }})</span>
		<!-- <span class="cmp corp_state_ing" v-if="$store.state.userInfo.mberDivCd != '22' && info.mberCorpState == '02'"><template v-if="info.corpNm">- 소속사 입사 승인대기중 -</template></span>
		<span class="cmp corp_state_ing" v-if="$store.state.userInfo.mberDivCd != '22' && info.mberCorpState == '01'"><template v-if="info.corpNm">- 소속사 퇴사 승인대기중 -</template></span>
		<span class="cmp" v-if="$store.state.userInfo.mberDivCd != '22' && info.mberCorpState == '03'"><template v-if="info.corpNm">{{info.corpNm}} / </template><SelectComp v-model="info.empDivCd" type="text" cdId="PRO102"/></span> -->

		<span v-if="isAdmin">
			<!-- <span class="cmp corp_state_ing" v-if="(info.empDivCd == null || info.empDivCd =='')"><template v-if="info.corpNm">- 소속사 미등록 -</template></span>
			<span class="cmp" v-else-if="info.aftAsgApprYn == 'Y'"><template v-if="info.corpNm">{{info.corpNm}} / </template><SelectComp v-model="info.empDivCd" type="text" cdId="PRO102"/></span>
			<span class="cmp corp_state_ing" v-else ><template v-if="info.corpNm">- {{info.asgApprYn}} -</template></span> -->

			<span v-if="(info.empDivCd == null || info.empDivCd =='')" class="cmp corp_state_ing"><template>- 원소속사 미등록 -</template></span>
			<span v-else-if=" info.mberAsgInfo.asgApprStatus == '02'" class="cmp corp_state_ing">- 승인 처리중 -</span>
			<span v-else-if=" info.mberAsgInfo.asgApprStatus == '04'" class="cmp corp_state_ing">- 퇴사 처리중 -</span>
			<span v-else class="cmp">{{ info.mberAsgInfo.corpNm || '소속없음'}} / <SelectComp v-model="info.mberAsgInfo.empDivCd" type="text" cdId="PRO102"/></span>

		</span>	

		<div class="mem_num">
			<!-- <div v-if="isMy && info.techGradeVerSeq != undefined" class="img_box"> -->
			<div v-if="isMy" class="img_box">
				<div class="cham" @click="changeChamState()">
					<img src="/images/tec/myp/cham_mem.png" alt="참여소속사"/>
					<div class="dev_state">
						<div class="text corp_state_ing" v-if="info.mberAsgInfo.joinAsgApprStatus == '01'">-</div>
						<div class="text corp_state_ing" v-else-if="info.mberAsgInfo.joinAsgApprStatus == '02'">-승인 처리중-</div>
						<div class="text" v-else>{{info.mberAsgInfo.joinCorpNm || '-'}}</div> 
					</div>
				</div>
				<div class="state" @click="changeDevState()">
					<img src="/images/tec/myp/mypage_develop_p.png" alt="참여가능날짜 설정" />
					<div class="dev_state">
						<div class="text">
							{{possDate | date('yyyy. MM. dd 부터')}}
						</div>
					</div>
				</div>
			</div>
			<div class="img_box" v-else>
				<div v-if="isAdmin" class="cham">
					<img src="/images/tec/myp/cham_mem_blank.png" alt="참여소속사"/>
					<div class="dev_state">
						<!-- <div class="text" v-if="info.projectJoinCorpNm">
							{{info.projectJoinCorpNm}}
						</div>
						<div class="text corp_state_ing" v-else>
							-소속없음-
						</div> -->

						<div class="text corp_state_ing" v-if="info.mberAsgInfo.joinAsgApprStatus == '01'">-</div>
						<div class="text corp_state_ing" v-else-if="info.mberAsgInfo.joinAsgApprStatus == '02'">-승인 처리중-</div>
						<div class="text" v-else>{{info.mberAsgInfo.joinCorpNm || '-'}}</div> 


					</div>
				</div>	
				<div v-if="possDate != '-'">
					<img src="/images/tec/myp/project_possible.png" alt="참여가능"/>
					<span v-if="isPmg" class="dev_state_pmg">{{possDate | date('yyyy. MM. dd')}} 부터</span>
					<span v-else class="dev_state">{{possDate | date('yyyy. MM. dd')}} 부터</span>
				</div>
				<div v-else>
					<img src="/images/tec/myp/project_impossible.png" alt="참여불가능"/>
					<!-- <span v-if="isPmg" class="dev_state_pmg">{{possDate | date('yyyy. MM. dd')}} 부터</span>
					<span v-else class="dev_state">{{possDate | date('yyyy. MM. dd')}} 부터</span> -->
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	props:{
		value:Object
	},

	updated() {
		//console.log("mounted");
		//console.log(this.value);
	},
	computed:{
		info(){
			return this.value && this.value.tecSeq ? this.value : {};
		},
		isAdmin() { return this.$store.getters.isAdmin },
		isMy() { return this.$store.state.userInfo.mberSeq == this.info.tecSeq },
		isPmg() { return this.$store.state.userInfo.mberDivCd == '22'; },
		possDate() {
			return this.value.joinPossYyyymmdd ? this.value.joinPossYyyymmdd : '-'
		}
	},
	
	methods : {
		changeDevState() {
			var param = {
				value : this.value
			}
			this.$.popup('/tec/myp/MYP101P01', param)			
		},
		changeChamState() {
			
			this.$router.push('PRF104M01');

			// if(this.value.mberCorpState == "03" || this.value.mberCorpState == undefined) {
			// 	var param = {
			// 		projectJoinCorpSeq : this.value.projectJoinCorpSeq,
			// 		projectJoinCorpNm : this.value.projectJoinCorpNm,


			// 		corpNm : this.value.corpNm,
			// 		corpSeq : this.value.corpSeq,

					

			// 		tecSeq : this.value.tecSeq,
			// 	}

			// 	console.log("corpNm : " + this.value.corpNm);
			// 	console.log("corpSeq : " + this.value.corpSeq);

			// 	this.$.popup('/tec/myp/MYP101P02', param);			
			// } else {
			// 	alert("미승인된 승인 요청 건이 있습니다. 승인 완료 후 요청이 가능합니다!");
			// }
			
		}
	}
}
</script>