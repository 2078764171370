<template>
	<component :is="tagNm || 'div'" @click="$emit('click', value, $event)">
		<slot name="title">{{title}}</slot>
		<template v-if="list.length > 0">
			<slot v-for="(item, idx) in list" :item="item" :index="idx">
				<div :key="item.evalItemDivCd" :class="getItemClass(idx, item)" @click="$emit('clickItem', item)">
					<slot :item="item" :index="idx">
						<SelectComp tagName="p" class="text" type="text" :value="item[cdKey]" cdId="PRO110"/>
						<grade-img :type="type" :grade="item[gradeKey]"/>
					</slot>
				</div>
			</slot>
		</template>
		<!-- 평가 없는 경우 -->
		<slot name="empty" v-else></slot>
	</component>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
export default {
	components: { gradeImg },
	props: { value:{}, title:String, type:String, cdKey : { type:String, default:'cd' }, gradeKey: { type:String, default:'grade' }, tagNm:String, itemClass:{} },
	data(){
		return { }
	},
	mounted(){
	},
	methods: {
		getItemClass(index){
			var ret = this.itemClass || '';
			if(index == 0) ret += ' first';
			return ret;
		}
	},
	computed: {
		list(){
			if(Array.isArray(this.value)){
				return this.value;
			} else {
				var list = [];

				if(typeof this.value == 'string'){
					var sp1 = this.value.split(',');
					for(var i1 in sp1){
						var sp2 = sp1[i1].split(':');
						var item = {};
						item[this.cdKey]    = sp2[0];
						item[this.gradeKey] = sp2[1];
						list.push(item);
					}
				}
				//console.debug('EvalDivGrade', 'list', this.value, list.length, list);
				return list;
			}
		}
	}
}
</script>