<template>
	<div class="contents-body myp" :class="{'hr_evl' : isPmg}">
		<div class="profile_box">
			<div class="image_box">
				<ImageComp v-if="info.gender == 'M'" :src="imgSrc" defaultSrc="PROFILE"/>
				<ImageComp v-if="info.gender == 'F'" :src="imgSrc" defaultSrc="PROFILE_W"/>
			</div>
			<template v-if="isPmg">
				<div class="name">경 력 <span class="age">{{info.totWorkMonthCnt | months}}</span></div>
				<div class="prj_itv" v-if="info.projectNowTerm" @click="showProjectRecrRate()"><img src="/images/prj_pf.png" alt="프로젝트수행중"/><p class="pf">프로젝트 수행중</p></div>
				<div class="prj_itv" @click="reqInteview()"><img src="/images/prj_itv.png" alt="프로젝트인터뷰요청"/><p>프로젝트 인터뷰 요청</p></div>
				<div class="human_int" @click="setInterestYn()"><img :src="info.interestYn == 'Y' ? '/images/human_int_btn01.png' : '/images/human_int_btn00.png'" alt="관심인재"/>관심인재</div>
			</template>
			<template v-else>
				<div class="name">{{info.mberNm}} <span class="age">({{info.gender | gender}}/{{ info.birthYyyymmdd | birthYear }})</span></div>
				<img class="prj_state" src="/images/admin/prj_pt.png" alt="프로젝트참여중" v-if="isMy && info.projectNowYn == 'Y'">
				<div class="inform btn_cursor" @click="click('INTERVIEW')">인터뷰 요청:   <span class="count">{{info.interviewCount.substr(1) }}</span><span class="al" v-if="info.interviewCount.charAt(0)  == 'Y'">N</span></div>
				<div class="inform btn_cursor" @click="click('RESMVIEW')" >내 이력서 열람:<span class="count">{{info.resumeViewCount.substr(1)}}</span><span class="al" v-if="info.resumeViewCount.charAt(0)   == 'Y'">N</span></div>
				<div class="inform btn_cursor" @click="click('INTREST')"  >관심인재 등록: <span class="count">{{info.interestCount.substr(1)  }}</span><span class="al" v-if="info.interestCount.charAt(0) == 'Y'">N</span></div>
				<div class="inform btn_cursor" @click="click('EDITINFO')" v-if="isMy"><img src="/images/tec/myp/pen_ico.png" alt="정보수정"/>정보수정하기</div>
				<div class="inform btn_cursor" @click="click('PRINT')"><img src="/images/tec/myp/print_ico.png" alt="이력서출력"/><p>이력서<br/>출 력</p></div>
			</template>
		</div>
		<top-info :value="info" class="member-box" v-if="isPmg"/>
		<div class="mypage_pf">
			<top-info :value="info" class="myp_box" v-if="!isPmg"/>

			<!-- 1번째 라인 -->
			<div class="myp_box top">
				<div class="ct" v-if="!isPmg">
					<div class="profile"><div class="label myp">아이디    </div><div class="value">{{info.loginId}}</div></div>
					<div class="profile"><div class="label myp">휴대폰번호</div><div class="value">{{info.hpNo | phone}}</div></div>
					<div class="profile"><div class="label myp">이메일    </div><div class="value">{{info.email}}</div></div>
					<div class="profile"><div class="label myp">생년월일  </div><div class="value">{{info.birthYyyymmdd | date('yyyy년 MM월 dd일')}}</div></div>
				</div>

				<div class="ct rank" v-if="isAdmin">
					<div class="cstom">
						평균 평가등급
						<grade-img type="TOT_EVAL" class="rank" :grade="info.totEvalGradeCd" :specialYn="info.specialGradeYn"/>
						<SelectComp tagName="p" class="rank" type="text" :value="info.totEvalGradeCd" v-if="info.totEvalGradeCd" cdId="PRO126"/>
						<p class="rank" v-else>미평가</p>
					</div>
				</div>
				<div class="ct evl ranking" v-else-if="isPmg">
					<div class="cstom">
						평균 평가등급
						<div class="eval none">
							<grade-img type="TOT_EVAL" class="ranking" :grade="info.totEvalGradeCd" :specialYn="info.specialGradeYn"/>
						</div>
						<SelectComp tagName="p" class="raking" type="text" :value="info.totEvalGradeCd" v-if="info.totEvalGradeCd" cdId="PRO126"/>
						<p class="raking" v-else>미평가</p>
					</div>
				</div>
				
				<!-- <div class="ct" :class="{evl:isPmg}" v-if="isPmg || isMy">
					<eval-div-grade class="cstom" title="수행사 평가" :value="info.clientEval" type="CLIENT_EVAL_SM" itemClass="eval">
						<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
					</eval-div-grade>
				</div> -->

				<!-- <div class="ct" v-if="isMy">
					<eval-div-grade class="cstom" title="수행사 평가" :value="info.clientEval" type="CLIENT_EVAL_SM" itemClass="eval">
						<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
					</eval-div-grade>
				</div> -->

				<template v-if="isPmg">
					<div class="ct evl">
						<eval-div-grade class="cstom" title="수행사 평가" :value="info.clientEval" type="CLIENT_EVAL_SM" itemClass="eval">
							<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
						</eval-div-grade>
					</div>

					<div class="ct evl">
						<eval-div-grade class="cstom" title="하이프로 평가" :value="info.pronEval" type="PRON_EVAL" itemClass="eval">
							<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
						</eval-div-grade>
					</div>
					<div class="ct amount hr">
						<p>수행완료 프로젝트</p>
						<span class="amount hr">{{info.projectEndCnt}}건</span>
						<div class="amount_history" @click="popupProject">수행완료 프로젝트 모두보기 &#9654;</div>
					</div>
				</template>

				<div class="ct amount" v-if="isAdmin">
					<img src="/images/tec/myp/point_ico.png" alt="적립포인트"/> 적립포인트
					<span class="amount">{{info.pointSum | number}}</span>
					<div class="amount_history" @click="click('POINT')">적립내역 &#9654;</div>
				</div>

				<div class="ct ncs" v-if="isMy">
					<div class="mberAsg">
						<!-- <span v-if="info.empDivCd == null || info.empDivCd == ''"> -->
						<span v-if="(info.empDivCd == null || info.empDivCd == '') && (info.mberAsgInfo.mberAsgHistorySeq == null || info.mberAsgInfo.mberAsgHistorySeq == '')">
							<span class="tit">회원 소속사</span>
							<div class="profile mt-2-5"><div class="label myp">원소속사</div><div class="value">-</div></div>
							<div class="profile"><div class="label myp">직원구분</div><div class="value">-</div></div>
							<div class="profile"><div class="label myp">상태</div><div class="value">-</div><div class="mberAsg_btn" @click="click('ASGMNG')">소속사 관리 &#9654;</div></div>
						</span>
						<span v-else>
							<span class="tit">회원 소속사</span>
							<div class="profile mt-2-5">
								<div class="label myp">원소속사</div>
								<div class="value">
									<span v-if=" info.mberAsgInfo.asgApprStatus == '01'">소속없음</span>
									<span v-else-if=" info.mberAsgInfo.asgApprStatus == '02'">{{ info.mberAsgInfo.aftAsgNm }}</span>
									<span v-else >{{ info.mberAsgInfo.corpNm || '소속사 없음'}}</span>
								</div>
							</div>

							<div class="profile">
								<div class="label myp">직원구분</div>
								<div class="value"><SelectComp v-model="info.aftEmpDivCd" type="text" cdId="PRO102"/>
									<span v-if=" info.mberAsgInfo.asgApprStatus == '01'">프리랜서</span>
									<span v-else-if=" info.mberAsgInfo.asgApprStatus == '02'"><SelectComp v-model="info.mberAsgInfo.aftEmpDivCd" type="text" cdId="PRO102"/></span>
									<span v-else ><SelectComp v-model="info.mberAsgInfo.empDivCd" type="text" cdId="PRO102"/></span>
								</div>
							</div>

							<div class="profile">
								<div class="label myp">상태</div>
								<div class="value">
									<span v-if=" info.mberAsgInfo.asgApprStatus == '02'">승인 처리중</span>
									<span v-else-if=" info.mberAsgInfo.asgApprStatus == '04'">퇴사 처리중</span>
									<span v-else>승인</span>
								</div>
								<div class="mberAsg_btn" @click="click('ASGMNG')">소속사 관리 &#9654;</div>
							</div>

						</span>
					</div>
				

					<!-- <ncs class="cstom" :value="info" @clickItem="click('NCS', $event)">
						<template v-slot:title>NCS 자가평가(직무 / 등급)<div class="ncs_shortcut" @click="click('NCS')" :class="{none: isPmg}">NCS 자가평가 하기 &#9654;</div></template>
					</ncs> -->
				</div>
			</div>

			<!-- 2번째 라인 -->
			<div class="myp_box top" :class="{none: isPmg}">
				<div class="ct edu">
					<div class="cstom">
						기술등급_학력기준<br/>
						<grade-img class="ranking" type="SCHOOL_TECH_MY" :grade="info.resumeSchoolTechGradeCd"/>
					</div>
				</div>
				<div class="ct edu">
					<div class="cstom">
						기술등급_자격기준<br/>
						<grade-img class="ranking" type="LICENSE_TECH_MY" :grade="info.resumeLicenseTechGradeCd"/>
					</div>
				</div>
				
				<template v-if="isAdmin">
					<div class="ct rank">
						<eval-div-grade class="cstom btn_cursor" title="수행사 평가" :value="info.clientEval" type="CLIENT_EVAL_SM" itemClass="eval" @click="click('CLIENT_EVAL')">
							<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
						</eval-div-grade>
					</div>
					<div class="ct rank last">
						<eval-div-grade class="cstom btn_cursor" title="하이프로 평가" :value="info.pronEval" type="PRON_EVAL" itemClass="eval" @click="click('PRON_EVAL')">
							<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
						</eval-div-grade>
					</div>
				</template>

				<div class="ct ncs" v-else-if="isPmg">
					<ncs class="cstom" :value="info" @clickItem="click('NCS', $event)">
						<template v-slot:title>NCS 자가평가(직무 / 등급)<div class="ncs_shortcut none" @click="click('NCS')" >NCS 자가평가 하기 &#9654;</div></template>
					</ncs>
				</div>

				<template v-else>
					<div class="ct">
						<eval-div-grade class="cstom ta-left" title="수행사 평가" :value="info.clientEval" type="CLIENT_EVAL_SM" itemClass="eval">
							<template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
						</eval-div-grade>
					</div>
					<div class="ct amount">
						<img src="/images/tec/myp/point_ico.png" alt="적립포인트"/> 적립포인트
						<span class="amount">{{info.pointSum | number}}</span>
						<div class="amount_history" @click="click('POINT')">적립내역 &#9654;</div>
					</div>
				</template>



				<!-- <div class="ct ncs" v-else>
					<ncs class="cstom" :value="info" @clickItem="click('NCS', $event)">
						<template v-slot:title>NCS 자가평가(직무 / 등급)<div class="ncs_shortcut" @click="click('NCS')" :class="{none: isPmg}">NCS 자가평가 하기 &#9654;</div></template>
					</ncs>
				</div> -->
			</div>

			<!-- 3번째 라인 -->
			<div class="bottom-box" v-if="isPmg">
				※ 평균 평가등급 : 수행사 평가와 하이프로 평가의 합계 평균값 입니다.<br/>
				※ 수행사 평가는 최근 5개 프로젝트 평가의 평균값을 의미하며, 하이프로 평가는 전체 수행기간에 대한 평가를 의미합니다.<br/>
				※ NCS 등급 : 초급(L2~L4), 중급(L5~L6), 고급(L7), 특급(L7~L8)<br/>
				※ 수행사 평가는 최근 5개 프로젝트 평가의 평균값을 의미합니다.
			</div>
			<div class="myp_box top" style="border-bottom: none;" v-else>
				<div class="ct edu">
					<div class="cstom prj">
						참여중인 프로젝트
						<!-- 수행중인 프로젝트가 없을 경우 -->
						<template v-if="info.projectNow == null || info.projectNow.length == 0">
							<div class="none">&lowbar; &lowbar; &lowbar; &lowbar;</div>
							<!-- 수행중인 프로젝트가 없을 경우 내 프로젝트로 가기 버튼 비활성화 class -> disable -->
							<div class="myprj disable">내 프로젝트로 가기 &#9654;</div>
						</template>
						<template v-else>
							<div @click="click('PROJ_NOW', info.projectNow[0])">
								<div class="prj_name">'{{info.projectNow[0].projectNm}}'</div>
								<div class="prj_date">{{info.projectNow[0].joinStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{info.projectNow[0].joinEndYyyymmdd | date('yyyy.MM.dd')}}</div>
							</div>
							<div class="myprj" @click="click('PROJ_NOW')">내 프로젝트로 가기 &#9654;</div>
						</template>
					</div>
				</div>
				<div class="ct edu">
					<div class="cstom prj">
						<span class="nt" v-if="info.projectNext != null && info.projectNext.length > 0 && info.projectNext[0].newYn == 'Y'">N</span>
						참여예정 프로젝트
						<!-- 수행중인 프로젝트가 없을 경우 -->
						<template v-if="info.projectNext == null || info.projectNext.length == 0">
							<div class="none">&lowbar; &lowbar; &lowbar; &lowbar;</div>
							<!-- 수행중인 프로젝트가 없을 경우 내 프로젝트로 가기 버튼 비활성화 class -> disable -->
							<div class="myprj disable">내 프로젝트로 가기 &#9654;</div>
						</template>
						<template v-else>
							<div @click="click('PROJ_BEF', info.projectNext[0])">
								<div class="prj_name">'{{info.projectNext[0].projectNm}}'</div>
								<div class="prj_date">{{info.projectNext[0].joinStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{info.projectNext[0].joinEndYyyymmdd | date('yyyy.MM.dd')}}</div>
							</div>
							<div class="myprj" @click="click('PROJ_BEF')">내 프로젝트로 가기 &#9654;</div>
						</template>
					</div>
				</div>

				<!-- <div class="ct ncs" v-if="isAdmin">
					<ncs class="cstom" :value="info" @clickItem="click('NCS', { indMberSeq: techMberSeq, techGradeVerSeq : info.techGradeVerSeq, detailClassCd:$event.detailClassCd })">
						<template v-slot:title>
							<p class="ncs">
								NCS 자가평가(직무 / 등급)
								<span @click="reqNCS()" v-if="!info.ncsGrade || info.ncsGrade.length == 0">평가요청 &#9654;</span>
							</p>
						</template>
					</ncs>
				</div> -->

				<div class="ct ncs" v-if="!isPmg">
					<ncs class="cstom" :value="info" @clickItem="click('NCS', { indMberSeq: techMberSeq, techGradeVerSeq : info.techGradeVerSeq, detailClassCd:$event.detailClassCd })" v-if="isAdmin">
						<template v-slot:title>
							<p class="ncs">
								NCS 자가평가(직무 / 등급)
								<span @click="reqNCS()" v-if="!info.ncsGrade || info.ncsGrade.length == 0">평가요청 &#9654;</span>
							</p>
						</template>
					</ncs>

					<ncs class="cstom" :value="info" @clickItem="click('NCS', $event)" v-else-if="isMy">
						<template v-slot:title>NCS 자가평가(직무 / 등급)<div class="ncs_shortcut" @click="click('NCS')">NCS 자가평가 하기 &#9654;</div></template>
					</ncs>
				</div>

				<!-- <div class="ct ncs" v-else-if="isMy">
					<div class="cstom guide">
						※ NCS 등급 : 초급 (L2~L4), 중급 (L5~L6), 고급 (L7), 특급 (L7~L8)
					</div>
					<div class="cstom guide">
						※ 수행사 평가는 최근 5개 프로젝트 평가의 평균값을 의미합니다.
					</div>
					<div class="cstom guide">
						※ 학력, 자격증 보유 현황, 경력 및 업무수행 이력 등의 정보가 변경되면<span>NCS 자가진단 평가를 다시 할 수 있습니다.</span>
					</div>
				</div> -->
			</div>
		</div>

		<slot name="bottom">
		</slot>
	</div>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
import topInfo from "@/components/highpro/TecInfo/TecInfoTopInfo.vue";
import ncs from "@/components/highpro/TecInfo/TecInfoNCS.vue";
import evalDivGrade from "@/components/highpro/EvalDivGrade.vue";
export default {
	components: { gradeImg, topInfo, evalDivGrade, ncs },
	props: {
		value: Object,
		type : String,
		tecMberSeq: {}
	},
	data(){
		return {
			techMberSeq: '',
			imgSrc: ''
		}
	},
	watch: {
		tecMberSeq(){
			this.search(true);
		}
	},
	mounted(){
 		this.search();
	},
	methods: {
        search(check = false){
			if(!check  || this.tecMberSeq != this.techMberSeq){
				if(this.tecMberSeq){
					this.techMberSeq = this.tecMberSeq;
				} else if(this.$store.state.userInfo.mberDivCd == '11'){
					this.techMberSeq = this.$store.state.userInfo.mberSeq;
				} else {
					this.techMberSeq = '';
					this.$emit('input', {});
				}

				if(this.techMberSeq){
					if(this.isAdmin || this.isMy){
						this.imgSrc = '/api/mem/prf/profile?tecMberSeq='+this.techMberSeq;
					}

					

					this.$.httpPost('/api/mem/prf/getTecMyPage', { tecMberSeq : this.techMberSeq })
						.then(res => {
							this.$emit('input', res.data);
						});
				}
			}
        },
		printResume(){
			//console.log('TecInfo printResume');

			this.$.httpPost('/api/mem/myp/getResumeAddYn', {techMberSeq : this.techMberSeq})
				.then(res => {
					if(res.data.resAddYn == 'Y') {
						window.open("/REPORT?tecMberSeq=" + this.techMberSeq + "&fileName=MEM910R01","report","width=900,height=800, scrollbars=yes, resizable=yes");
					}else {
						alert('이력서 등록 전 입니다.');
						return false;
					}
				})
		},
		click(type, item){
			//console.log('TecInfo click', type, item);
			if(this.isAdmin && type.toUpperCase() != 'PRINT'){
				this.$emit('click', {type, item});
			} else {
				switch(type.toUpperCase()){
					case 'NCS'		: 
						if(!item) this.move(type, 'PRF102M02', {type:'add'});
						else if(item.evlYn != 'Y') this.move(type, 'PRF102M02', {detailClassCd:item.detailClassCd});
						else this.popup(type, '/dco/pmg/prf/PRF301P05', { indMberSeq: this.techMberSeq, techGradeVerSeq : this.info.techGradeVerSeq, detailClassCd: item.detailClassCd });
						break;
					case 'PROJ_NOW'	: 
					case 'PROJ_BEF'	: 
						if(item) this.popup(type, '/tec/prj/PRJ102P01', { projectSeq : item.projectSeq });
						else this.move(type, 'PRJ102M01')
						break;
					case 'INTERVIEW': this.move(type, 'PRJ103M01'); break;
					case 'INTREST'	: this.move(type, 'PRF103M02'); break;
					case 'EDITINFO'	: this.move(type, 'MYP102M01'); break;
					case 'POINT'	: this.move(type, 'PIT101M01'); break;
					case 'RESMVIEW'	: this.move(type, 'PRF103M01'); break;
					case 'ASGMNG'	: this.move(type, 'PRF104M01'); break;
					case 'PRINT'    : this.printResume(); break;
				}
			}
		},
		move(type, name, params){
			//console.log('TecInfo move', type, name, params);
			if(name != null){
				this.$emit('move', type.toUpperCase(), params);
				this.$router.push({name, params});
			} else {
				//console.log('TecInfo move', type);
			}
		},
		popup(type, path, params){
			if(path != null){
				this.$emit('popup', type.toUpperCase(), params);
				this.$.popup(path, params);
			} else {
				//console.log('TecInfo popup', type);
			}
		},

		
		/* 수요기업 프로젝트관리자 */
		showProjectRecrRate(){
			//console.log(this.info.projectNowTerm)
			if(this.info.projectNowTerm && this.info.projectNowTerm.length > 0){
				var term = this.info.projectNowTerm[0]
				this.$.popup('/dco/pmg/prf/PRF301P04', { recrStartYyyymmdd: term.joinStartYyyymmdd, recrEndYyyymmdd : term.joinEndYyyymmdd });
			}
		},
		reqInteview(){
			this.$.popup('/dco/pmg/prf/PRF301P02', { tecMberSeq: this.tecMberSeq })
				.then(res => {
					if(res){
						//this.search(this.tecMberSeq);
						this.$emit('updated', 'INTERVIEW', res);
					}
				});
		},
		setInterestYn(){
			this.$.popup('/dco/pmg/prf/PRF301P01', { tecMberSeq: this.tecMberSeq, regYn: this.info.interestYn == 'Y' ? 'N' : 'Y' })
				.then(res => {
					if(res){
						this.search();
						this.$emit('updated', 'INTEREST', res);
					}
				});
		},
		popupProject(){
        	//console.log('TecProfileSub01', 'popupProject', this.data);
			this.$emit('popup', 'PROJECT');
			if(this.info.projectEndCnt == 0) {
				alert("수행 완료된 프로젝트가 없습니다.");
			} else {
				this.$.popup('/dco/pmg/prf/PRF301P06', { tecMberSeq: this.techMberSeq });
			}
		},

		/* 관리자 */
		reqNCS(){
			this.$.popup('/adm/mem/MEM912P02', { tecMberSeq: this.techMberSeq });

		},
	},
	computed: {
		info(){
			if(this.value && Object.keys(this.value).length > 0){
				return this.value;
			} else {
				return {
					birthYyyymmdd: '',
					interestCount: '',
					interviewCount: '',
					resumeViewCount: '',
				};
			}
		},
		isAdmin() { return this.$store.getters.isAdmin },
		isMy() { return this.$store.state.userInfo.mberSeq == this.techMberSeq },
		isPmg() { return this.$store.state.userInfo.mberDivCd == '22'; },
	}
}
</script>